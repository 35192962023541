import React, {FC} from 'react';
import {useNavigate} from 'react-router-dom';

import Box from '@mui/material/Box';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';

import ResponsiveHeader from '@geomatico/geocomponents/Layout/ResponsiveHeader';
import SidePanel from '@geomatico/geocomponents/Layout/SidePanel';
import MiniSidePanel from '@geomatico/geocomponents/Layout/MiniSidePanel';

import {
  DRAWER_WIDTH,
  MINI_SIDE_PANEL_DENSE_WIDTH,
  MINI_SIDE_PANEL_WIDTH,
  MINISIDEPANEL_CONFIG,
  Mode,
  SM_BREAKPOINT,
} from '@/config';
import styled from '@mui/system/styled';
import useMediaQuery from '@mui/material/useMediaQuery';

import LogoTMBNegativo from '../../resources/logos/LogoTMBNegativo';
import CalendarPicker from './forms/CalendarPicker';
import {Dayjs} from 'dayjs';
import {IconButton} from '@mui/material';

export type MainProps = {
  widescreen: boolean,
  isleftdraweropen: boolean
}

const Main = styled(Box)<MainProps>(({widescreen, isleftdraweropen}) => ({
  flexGrow: 1,
  padding: 0,
  position: 'absolute',
  width: `calc(100% - ${widescreen ? MINI_SIDE_PANEL_WIDTH : MINI_SIDE_PANEL_DENSE_WIDTH}px)`,
  //width: '100%',
  top: 56,
  '@media (min-width: 0px) and (orientation: landscape)': {
    top: 48
  },
  ['@media (min-width: ' + SM_BREAKPOINT + 'px)']: {
    top: 64
  },
  bottom: 0,
  right: 0,
  //left: widescreen ? +(isleftdraweropen && DRAWER_WIDTH) + MINI_SIDE_PANEL_WIDTH : MINI_SIDE_PANEL_DENSE_WIDTH
  left: widescreen ? MINI_SIDE_PANEL_WIDTH : MINI_SIDE_PANEL_DENSE_WIDTH
})) as React.ElementType;

const responsiveHeaderSx = {
  '&.MuiAppBar-root': {
    zIndex: 1500
  }
};

export type LayoutProps = {
  mainContent: React.ReactElement,
  sidePanelContent: React.ReactElement,
  miniSidePanelSelectedActionId: Mode,
  day: Dayjs,
  onActionChanged: (id: Mode ) => void,
  onDayChanged: (day: Dayjs) => void,
  onLogout: () => void,
};

const Layout: FC<LayoutProps> = ({
  mainContent,
  sidePanelContent,
  miniSidePanelSelectedActionId,
  day,
  onActionChanged,
  onDayChanged,
  onLogout
}) => {
  const navigate = useNavigate();
  const widescreen = useMediaQuery(`@media (min-width:${SM_BREAKPOINT}px)`, {noSsr: true});

  const handleActionClick = (id: string) => {
    if (Object.values(Mode).includes(id as Mode)) {
      const mode: Mode = id as Mode;
      if (miniSidePanelSelectedActionId === mode && sidePanelContent) {
        onActionChanged(Mode.VIEW);
      } else {
        onActionChanged(mode);
      }
    }
  };

  const sidePanelSx = {
    '& .MuiPaper-root': {
      position: 'absolute',
      left: widescreen ? MINI_SIDE_PANEL_WIDTH : MINI_SIDE_PANEL_DENSE_WIDTH
    }
  };

  const miniSidePanelSx = {
    '& .MiniSidePanel-item': {
      bgcolor: 'primary.contrastText'
    },
    '& .MiniSidePanel-item:hover': {
      bgcolor: 'grey.300'
    },
    '& .MiniSidePanel-content:hover': {
      color: 'grey.700'
    },
    '& .MiniSidePanel-itemSelected': {
      bgcolor: 'primary.lighter'
    },
    '& .MiniSidePanel-itemSelected:hover': {
      bgcolor: 'secondary.main'
    },
    '& .MiniSidePanel-contentSelected:hover': {
      color: 'primary.contrastText'
    },
  };

  return <>
    <ResponsiveHeader
      title='Bus Inventory Admin'
      logo={<LogoTMBNegativo width='80%' style={{marginLeft: 10}}/>}
      isStartIconCloseable={false}
      sx={responsiveHeaderSx}
    >
      <CalendarPicker
        value={day} label="" readonly={false} required={true}
        onChange={day => day && day.isValid() && onDayChanged(day)}/>
      <IconButton aria-label="login" size='large' color='secondary' onClick={onLogout}>
        <AccountCircleIcon sx={{fontSize: '40px'}}/>
      </IconButton>
    </ResponsiveHeader>
    <MiniSidePanel
      actions={MINISIDEPANEL_CONFIG}
      selectedActionId={`${miniSidePanelSelectedActionId}` || ''}
      onActionClick={handleActionClick}
      dense={!widescreen}
      sx={miniSidePanelSx}
    />
    {sidePanelContent && miniSidePanelSelectedActionId !== Mode.VIEW &&
      <SidePanel
        drawerWidth={DRAWER_WIDTH + 'px'}
        anchor="left"
        isOpen={true}
        widescreen={widescreen}
        sx={sidePanelSx}
      >
        {sidePanelContent}
      </SidePanel>
    }
    <Main widescreen={widescreen} isleftdraweropen={!!miniSidePanelSelectedActionId}>
      {mainContent}
    </Main>
  </>;
};

export default Layout;
