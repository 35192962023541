import {AccesDto, AccesDtoCreation} from './accesDto';
import {Acces, AccesCreation} from '@/domain/entities/Acces';

export const toDto = (domain: Acces | AccesCreation): AccesDto | AccesDtoCreation => {
  return {
    ...domain,
    startDate: domain.startDate.toISOString(),
    endDate: domain.endDate?.toISOString()
  };
};

export const fromDto = (dto: AccesDto): Acces => {
  return {
    ...dto,
    startDate: new Date(dto.startDate),
    endDate: dto.endDate ? new Date(dto.endDate) : undefined,
    updatedAt: new Date(dto.updatedAt),
    createdAt: new Date(dto.createdAt),
  };
};