import Http from '@/domain/services/Http';
import {Repository} from '@/domain/services/Repository';
import {Acces, AccesCreation} from '@/domain/entities/Acces';
import {AccesDto} from './accesDto';
import {fromDto, toDto} from './accesMappers';


const PATH = 'accessos';

export const AccesRepository = (apiBaseUrl: URL | string, http: Http): Repository<Acces, AccesCreation> => ({
  getAll: (date) =>
    http<Array<AccesDto>>(new URL(`${PATH}?date=${date.toISOString()}`, apiBaseUrl))
      .then(trams => trams.map(tram => fromDto(tram))),
  create: (tram: AccesCreation) =>
    http(new URL(PATH, apiBaseUrl), {method: 'POST', body: toDto(tram)})
});
