import MapboxDraw, {DrawCustomModeThis, DrawLineString, DrawPoint, MapMouseEvent} from '@mapbox/mapbox-gl-draw';
import {Feature, LineString, Point, Position} from 'geojson';
import {
  buildMode,
  resetFeature,
  snapToTram,
  TmbDrawModeOptions,
  TmbDrawModeState
} from '@/components/snapping/snapping';
import lineIntersect from '@turf/line-intersect';
import distance from '@turf/distance';


export type DrawTramOptions = TmbDrawModeOptions<LineString>;

export const EVENT_TRAM_GEOMETRY = 'draw.tram.geometry';

export type TramGeometryEvent = {
  geometry: LineString,
  firstCuttingTramId?: number,
  secondCuttingTramId?: number,
}

type DrawTramState = TmbDrawModeState<LineString, DrawTramOptions> & {
  tramGeometry: DrawLineString
  // The next point to be drawn
  nextPoint: DrawPoint,
  nextSegment: DrawLineString,
}

const DRAW_NEXT_POINT_ID = 'nextPoint';
const DRAW_NEXT_SEGMENT_ID = 'nextSegment';
const DRAW_TRAM_GEOMETRY_ID = 'tramGeometry';

// we need to persist some information in the tram geometry properties so we could use them
// if the snapping features change in order to set up the state again
const PROP_DONE = 'done';
const PROP_FIRST_CUTTING_TRAM_ID = 'firstCuttingTramId';
const PROP_SECOND_CUTTING_TRAM_ID = 'secondCuttingTramId';

export default () => buildMode<LineString, DrawTramOptions, DrawTramState>({
  drawCustomMode: MapboxDraw.modes.draw_line_string,
  initializeState,
  onClick,
  onMouseMove
});

const initializeState = (
  customMode: DrawCustomModeThis, options: DrawTramOptions, filteredFeatures: Feature<LineString>[]
): DrawTramState => ({
  filteredSnappingData: filteredFeatures,
  tramGeometry: initializeTramGeometry(customMode, options, filteredFeatures),
  nextPoint: resetFeature<DrawPoint>(customMode, DRAW_NEXT_POINT_ID, 'Point'),
  nextSegment: resetFeature<DrawLineString>(customMode, DRAW_NEXT_SEGMENT_ID, 'LineString'),
  options
});

const initializeTramGeometry = (
  customMode: DrawCustomModeThis, options: DrawTramOptions, filteredFeatures: Feature<LineString>[]
): DrawLineString => {
  const tramGeometry = customMode.getFeature(DRAW_TRAM_GEOMETRY_ID) as DrawLineString;
  if (!tramGeometry || !tramGeometry.getCoordinates() || !tramGeometry.getCoordinates().length) {
    // if there's no line with at least one coordinate, reset new tram geometry
    return resetFeature<DrawLineString>(customMode, DRAW_TRAM_GEOMETRY_ID, 'LineString');
  }

  const isCoordinateValid = (coordinate: Position, expectedCuttingTramId: number) => {
    const snappedTramData = snapToTram(filteredFeatures, options, coordinate[0], coordinate[1]);
    if (expectedCuttingTramId === undefined) {
      // if it wasn't cutting, it shouldn't snap to a middle point now
      return snappedTramData === undefined || snappedTramData.isEndpoint;
    } else {
      // if it was cutting, it should do as well now, within a small distance
      return snappedTramData?.tramId === expectedCuttingTramId
        && !snappedTramData.isEndpoint
        && snappedTramData.distanceToTram < 5e-6;
    }
  };

  const crossesAnyExistingFeature = () => filteredFeatures
    .map(feature => findIntersections(feature.geometry, tramGeometry))
    .some(intersections => intersections.length > 0);

  const firstCoord = tramGeometry.coordinates[0];
  const lastCoord = tramGeometry.coordinates[tramGeometry.coordinates.length - 1];
  const firstCuttingTramId = tramGeometry.properties?.[PROP_FIRST_CUTTING_TRAM_ID];
  const secondCuttingTramId = tramGeometry.properties?.[PROP_SECOND_CUTTING_TRAM_ID];
  return (
    isCoordinateValid(firstCoord, firstCuttingTramId) &&
    isCoordinateValid(lastCoord, secondCuttingTramId) &&
    !crossesAnyExistingFeature()
  )
    ? tramGeometry
    : resetFeature<DrawLineString>(customMode, DRAW_TRAM_GEOMETRY_ID, 'LineString');
};

const onClick = (drawCustomMode: DrawCustomModeThis, state: DrawTramState) => {
  const addCoordinate = () => {
    const nextCoordinate = state.nextPoint.getCoordinate();
    state.tramGeometry.addCoordinate(state.tramGeometry.coordinates.length, nextCoordinate[0], nextCoordinate[1]);
    if (state.tramGeometry.coordinates.length === 1) {
      state.tramGeometry.setProperty(PROP_DONE, false);
      state.tramGeometry.setProperty(PROP_FIRST_CUTTING_TRAM_ID,
        state.snappedTramData?.isEndpoint === false ? state.snappedTramData?.tramId : undefined);
    } else if (state.snappedTramData) {
      // if snapping to tram and not the first point, assume end point of tram and trigger event
      state.tramGeometry.setProperty(PROP_DONE, true);
      state.tramGeometry.setProperty(PROP_SECOND_CUTTING_TRAM_ID,
        !state.snappedTramData.isEndpoint ? state.snappedTramData.tramId : undefined);

      const event: TramGeometryEvent = {
        geometry: state.tramGeometry,
        firstCuttingTramId: state.tramGeometry.properties?.[PROP_FIRST_CUTTING_TRAM_ID],
        secondCuttingTramId: state.tramGeometry.properties?.[PROP_SECOND_CUTTING_TRAM_ID],
      };
      drawCustomMode.map.fire(EVENT_TRAM_GEOMETRY, event);
    }
  };

  if (isDone(state)) {
    // if clicking when another tram geometry is already done, start a new one
    state.nextSegment = resetFeature<DrawLineString>(drawCustomMode, DRAW_NEXT_SEGMENT_ID, 'LineString');
    state.tramGeometry = resetFeature<DrawLineString>(drawCustomMode, DRAW_TRAM_GEOMETRY_ID, 'LineString');
    addCoordinate();
  } else if (intersectsWithExistingFeature(state)) {
    // if it crosses some existing feature, error
    // TODO handle intersections better
    window.alert('Nein');
  } else {
    addCoordinate();
  }
};

const onMouseMove = (drawCustomMode: DrawCustomModeThis, state: DrawTramState, event: MapMouseEvent) => {
  const snappedTramData = snapToTram(state.filteredSnappingData, state.options, event.lngLat.lng, event.lngLat.lat);
  state.snappedTramData = snappedTramData;
  if (!snappedTramData) {
    state.nextPoint.updateCoordinate(event.lngLat.lng, event.lngLat.lat);
  } else {
    state.nextPoint.updateCoordinate(snappedTramData.position.longitude, snappedTramData.position.latitude);
  }

  // do not update the tram geometry if it's marked as done
  if (state.tramGeometry.coordinates.length > 0 && !isDone(state)) {
    const lastCoordinate = state.tramGeometry.coordinates[state.tramGeometry.coordinates.length - 1];
    state.nextSegment.updateCoordinate('0', lastCoordinate[0], lastCoordinate[1]);
    state.nextSegment.updateCoordinate('1', state.nextPoint.getCoordinate()[0], state.nextPoint.getCoordinate()[1]);
  }
};

const isDone = (state: DrawTramState) => state.tramGeometry.properties?.[PROP_DONE] === true;

const intersectsWithExistingFeature = (state: DrawTramState) => (
  // next segment intersects with something already drawn
  findIntersections(state.tramGeometry, state.nextSegment).length > 0 ||
  // or with existing trams
  state.filteredSnappingData
    .map(feature => findIntersections(feature.geometry, state.nextSegment))
    .some(intersections => intersections.length > 0)
);

const findIntersections = (existing: LineString, drawing: LineString): Feature<Point>[] => {
  const firstCoord = drawing.coordinates[0];
  const lastCoord = drawing.coordinates[drawing.coordinates.length - 1];
  return lineIntersect(existing, drawing, {ignoreSelfIntersections: true}).features
    // ignore intersections that are too close to the start/end points of the drawing line
    .filter(intersection =>
      distance(intersection.geometry, firstCoord) > 1e-6 &&
      distance(intersection.geometry, lastCoord) > 1e-6
    );
};
