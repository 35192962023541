import React, {FC, useMemo} from 'react';

import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import styled from '@mui/styles/styled';

import GeomaticoLink from '@/components/GeomaticoLink';
import {Parada, ParadaCreation} from '@/domain/entities/Parada';
import ParadaDetail from '@/components/ParadaDetail';
import {ParadaType} from '@/domain/entities/ParadaType';
import {Mode, PARADA_DETAIL_MODES} from '@/config';

const ScrollableContent = styled(Box)({
  overflow: 'auto',
  padding: '8px',
});

const stackSx = {
  height: '100%',
  overflow: 'hidden'
};

export type SidePanelProps = {
  mode: Mode
  parada: Partial<Parada>
  paradaTypes?: ParadaType[]
  onParadaChange: (parada: Partial<Parada>) => void
  onParadaSaved: (parada: Parada | ParadaCreation) => void
}

const SidePanelContent: FC<SidePanelProps> = ({
  mode,
  parada,
  paradaTypes,
  onParadaChange,
  onParadaSaved
}) => {
  const content = useMemo(() => {
    if (mode === Mode.EDIT_BUS_STOP && !('id' in parada)) {
      // TODO TMB-421 Sacar a un componente, traducir, y poner debajo del título que ahora está dentro de ParadaDetail
      return <div>Pincha en una parada.</div>;
    } else if (PARADA_DETAIL_MODES.includes(mode) && paradaTypes) {
      return <ParadaDetail
        parada={parada}
        availableTypes={paradaTypes}
        onChange={onParadaChange}
        onSave={onParadaSaved}/>;
    } else {
      return undefined;
    }
  }, [mode, parada, paradaTypes]);

  return <Stack sx={stackSx}>
    <ScrollableContent>
      {content}
    </ScrollableContent>
    <GeomaticoLink/>
  </Stack>;
};

export default SidePanelContent;
