import {inject} from '@/domain/dependencyInjection';
import {REPOSITORIES, Repository} from '@/domain/services/Repository';
import {Parada, ParadaCreation} from '@/domain/entities/Parada';
import {OpRepository} from '@/repository/op/OpRepository';
import {TramIntersection} from '@/domain/entities/Tram';


export const getParades = async (date: Date): Promise<Parada[]> => {
  const repository = inject<Repository<Parada, ParadaCreation>>(REPOSITORIES.parada);
  return repository.list(date);
};

export const createParada = async (parada: ParadaCreation, tramIntersection?: TramIntersection) => {
  if (tramIntersection) {
    const repository = inject<OpRepository>(REPOSITORIES.op);
    return await repository.newParada(parada, tramIntersection);
  } else {
    const repository = inject<Repository<Parada, ParadaCreation>>(REPOSITORIES.parada);
    return await repository.create(parada);
  }
};

export const editParada = async (paradaId: number, parada: Parada): Promise<Parada> => {
  const repository = inject<Repository<Parada, ParadaCreation>>(REPOSITORIES.parada);
  return await repository.put(paradaId, parada);
};
