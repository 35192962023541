import {FeatureCollection, LineString, Point} from 'geojson';
import {getAllParadesAsFeatureCollection} from '@/domain/usecases/paradaUseCases';
import {getAllAccesosAsFeatureCollection} from '@/domain/usecases/accesUseCases';
import {getAllTramsAsFeatureCollection} from '@/domain/usecases/tramUseCases';
import {Dayjs} from 'dayjs';

export type BusInventoryFeatures = {
  parades: FeatureCollection<Point>,
  accessos: FeatureCollection<Point>,
  trams: FeatureCollection<LineString>,
}
const getAllFeatures = async (day: Dayjs): Promise<BusInventoryFeatures> => {
  const [parades, accessos, trams] = await Promise.all([
    getAllParadesAsFeatureCollection(day.toDate()),
    getAllAccesosAsFeatureCollection(day.toDate()),
    getAllTramsAsFeatureCollection(day.toDate()),
  ]);
  return {parades, trams, accessos};
};

export default getAllFeatures;