import {ParadaType} from '@/domain/entities/ParadaType';
import {inject} from '@/domain/dependencyInjection';
import {REPOSITORIES, Repository} from '@/domain/services/Repository';
import {RepositoryParadaType} from '@/repository/paradaType/paradaTypeRepository';


export const getParadaTypes = async (): Promise<ParadaType[]> => {
  const repository = inject<RepositoryParadaType>(REPOSITORIES.paradaType);

  return repository.getAll();
};

export default getParadaTypes;