import {FeatureCollection, Point} from 'geojson';
import {inject} from '@/domain/dependencyInjection';
import {REPOSITORIES, Repository} from '@/domain/services/Repository';
import {Parada, ParadaCreation} from '@/domain/entities/Parada';
import {OpRepository} from '@/repository/op/opRepository';
import {TramIntersection} from '@/domain/entities/Tram';


export const getAllParades = async (date: Date): Promise<Parada[]> => {
  const repository = inject<Repository<Parada, ParadaCreation>>(REPOSITORIES.parada);

  return repository.getAll(date);
};

export const getAllParadesAsFeatureCollection = async (date: Date): Promise<FeatureCollection<Point>> => {
  const parades = await getAllParades(date);

  return {
    type: 'FeatureCollection',
    features: parades.map(({position, id}) => ({
      type: 'Feature',
      id,
      properties: {},
      geometry: position
    }))
  };
};

export const createParada = async (parada: ParadaCreation, tramIntersection?: TramIntersection) => {

  if (tramIntersection) {
    const repository = inject<OpRepository>(REPOSITORIES.op);
    return await repository.newParada(parada, tramIntersection);
  } else {
    const repository = inject<Repository<Parada, ParadaCreation>>(REPOSITORIES.parada);
    return await repository.create(parada);
  }

};