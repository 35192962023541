export const REPOSITORIES = {
  acces: 'repository.acces',
  parada: 'repository.parada',
  tram: 'repository.tram',
  op: 'repository.op',
  paradaType: 'repository.parada-type'
};

export interface Repository<ENTITY, ENTITY_CREATION> {
  getAll: (date: Date) => Promise<Array<ENTITY>>
  create: (entity: ENTITY_CREATION) => Promise<ENTITY>
}

