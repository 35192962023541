import {Acces, AccesCreation} from '../entities/Acces';
import {FeatureCollection, Point} from 'geojson';
import {inject} from '@/domain/dependencyInjection';
import {REPOSITORIES, Repository} from '@/domain/services/Repository';

export const getAllAccesos = async (date: Date): Promise<Acces[]> => {
  const repository = inject<Repository<Acces, AccesCreation>>(REPOSITORIES.acces);

  return repository.getAll(date);
};

export const getAllAccesosAsFeatureCollection = async (date: Date): Promise<FeatureCollection<Point>> => {
  const acces = await getAllAccesos(date);

  return {
    type: 'FeatureCollection',
    features: acces.map(({position, id}) => ({
      type: 'Feature',
      id,
      properties: {},
      geometry: position
    }))
  };
};
