import {TramDto, TramDtoCreation} from './tramDto';
import {Tram, TramCreation} from '@/domain/entities/Tram';

export const toDto = (domain: Tram | TramCreation): TramDto | TramDtoCreation => {
  return {
    ...domain,
    startDate: domain.startDate.toISOString(),
    endDate: domain.endDate?.toISOString(),
  };
};

export const fromDto = (dto: TramDto): Tram => {
  return {
    ...dto,
    startDate: new Date(dto.startDate),
    endDate: dto.endDate ? new Date(dto.endDate) : undefined,
    updatedAt: new Date(dto.updatedAt),
    createdAt: new Date(dto.createdAt),
  };
};