import React, {FC, ReactElement} from 'react';

//MUI
import Box from '@mui/material/Box';
import Dialog from '@mui/material/Dialog';
import Typography from '@mui/material/Typography';
import {styled} from '@mui/material/styles';
import {SxProps} from '@mui/material';

//STYLES
const classes = {
  root: 'DialogPanel-root',
  header: 'DialogPanel-header',
  headerText: 'DialogPanel-headerText',
  contentText: 'DialogPanel-contentText',
  footer: 'DialogPanel-footer'
};

const Root = styled(Dialog)(({theme}) => ({
  zIndex: 2000,
  '& .DialogPanel-header': {
    marginBottom: '10px',
    paddingTop: '5px',
    paddingLeft: '10px',
    backgroundColor: theme.palette.primary.main,
    color: 'white',
    height: '30px'
  },
  '& .DialogPanel-headerText': {
    fontWeight: 900,
    paddingRight: 10,
  },
  '& .DialogPanel-contentText': {
    height: '70%',
    overflow: 'auto',
    margin: '0 8px',
  },
  '& .DialogPanel-footer': {
    marginTop: '10px',
    backgroundColor: theme.palette.primary.main,
    height: '5px'
  }
}));

export type DialogPanelProps = {
  isOpen: boolean,
  label: string,
  fullWidth?: boolean,
  onClose: () => void,
  sx?: SxProps,
  children: ReactElement
};

const DialogPanel: FC<DialogPanelProps> = ({
  isOpen = false,
  label = '',
  fullWidth = false,
  children,
  onClose,
  sx = {}
}) => {
  const handleModalClose = () => onClose && onClose();

  return (
    <Root
      id="bikenta-dialog"
      open={isOpen}
      onClose={handleModalClose}
      fullWidth={fullWidth}
      className={classes.root}
      sx={sx}
    >
      <Box className={classes.header}>
        <Typography className={classes.headerText}>{label}</Typography>
      </Box>
      <Box className={classes.contentText}>
        {children}
      </Box>
      <Box className={classes.footer}/>
    </Root>
  );
};


export default DialogPanel;