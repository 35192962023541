import {ParadaCreation} from '@/domain/entities/Parada';
import {ParadaDtoCreation} from '@/repository/parada/paradaDto';
import {TramIntersection} from '@/domain/entities/Tram';

export const toDto = (domain: ParadaCreation & TramIntersection): ParadaDtoCreation => {
  return {
    ...domain,
    startDate: domain.startDate.toISOString(),
    endDate: domain.endDate?.toISOString(),
  };
};