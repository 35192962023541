import {Parada, ParadaCreation} from '@/domain/entities/Parada';
import Http from '@/domain/services/Http';
import {ParadaDto, ParadaDtoCreation} from '../parada/paradaDto';
import {toDto} from './opMappers';
import {fromDto} from '../parada/paradaMappers';
import {TramIntersection} from '@/domain/entities/Tram';


const PATH= 'op';

export interface OpRepository {
  newParada: (newParada: ParadaCreation, tramIntersection: TramIntersection) => Promise<Parada>
  //moveParada: (parada: Parada) => Promise<Parada>
}

export const OpRepository = (apiBaseUrl: URL | string, http: Http): OpRepository => ({
  newParada: (parada, tramIntersection) =>
    http<ParadaDto, ParadaDtoCreation>(new URL(`${PATH}/new-parada`, apiBaseUrl), {method: 'POST', body: toDto({...parada, ...tramIntersection})})
      .then(parada => fromDto(parada)),
});

export type OpRepositoryType = typeof OpRepository;
