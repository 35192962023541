import Http from '@/domain/services/Http';
import {Repository} from '@/domain/services/Repository';
import {Tram, TramCreation} from '@/domain/entities/Tram';
import {TramDto} from './tramDto';
import {fromDto, toDto} from './tramMappers';


const PATH = 'trams';

export const TramRepository = (apiBaseUrl: URL | string, http: Http): Repository<Tram, TramCreation> => ({
  getAll: (date: Date) =>
    http<Array<TramDto>>(new URL(`${PATH}?date=${date.toISOString()}`, apiBaseUrl))
      .then(trams => trams.map(tram => fromDto(tram))),
  create: (tram: TramCreation) =>
    http(new URL(PATH, apiBaseUrl), {method: 'POST', body: toDto(tram)})
});