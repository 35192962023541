import React, {FC, useMemo} from 'react';
import Map from '@geomatico/geocomponents/Map/Map';

import {MAPSTYLES, Mode} from '@/config';
import BaseMapPicker from '@geomatico/geocomponents/Map/BaseMapPicker';
import mapboxgl from 'mapbox-gl';
import {Viewport} from '@geomatico/geocomponents/types/common';
import DrawParadaControl from '@/components/DrawParadaControl';
import {BusInventoryFeatures} from '@/domain/usecases/getAllFeatures';
import {ParadaLocationEvent} from '@/components/snapping/DrawParadaMode';

const MAX_DISTANCE_IN_METERS = 20;
const DRAW_MODES = [Mode.CREATE_BUS_STOP];


export type MainContentProps = {
  mode?: Mode,
  features?: BusInventoryFeatures,
  viewport: Viewport,
  mapStyle: string,
  onMapStyleChange: (style: string) => void,
  onViewportChange: (viewport: Viewport) => void
  onParadaPosition: (event: ParadaLocationEvent) => void
};

const MainContent: FC<MainContentProps> = ({
  mode,
  features,
  mapStyle,
  viewport,
  onMapStyleChange,
  onViewportChange,
  onParadaPosition
}) => {
  const drawEnabled = !!(mode && DRAW_MODES.includes(mode));
  const sources = useMemo((): mapboxgl.Sources => !features ? {} : ({
    'parades': {
      type: 'geojson',
      data: features.parades
    },
    'accessos': {
      type: 'geojson',
      data: features.accessos
    },
    'trams': {
      type: 'geojson',
      data: features.trams
    },
    'trams-vertex': {
      type: 'geojson',
      data: {
        type: 'FeatureCollection',
        features: mode === Mode.CREATE_BUS_STOP ? features.trams.features.map(feature => ({
          ...feature,
          geometry: {
            type: 'MultiPoint',
            coordinates: [feature.geometry.coordinates[0], feature.geometry.coordinates[feature.geometry.coordinates.length - 1]]
          }
        })) : []
      }
    },
  }), [features, mode]);

  /**
   * ({
*      ...feature,
*      geometry: {
*        type: 'point',
*        coordinates: feature.geometry.coordinates[0]
*      }
*    })
   * */

  // TODO TMB-403 symbolization
  const layers = useMemo((): Array<mapboxgl.AnyLayer> => !features ? [] : [{
    id: 'parades',
    type: 'circle',
    source: 'parades',
    paint: {
      'circle-color': '#FB5082',
      'circle-radius': 5
    }
  }, {
    id: 'accessos',
    type: 'circle',
    source: 'accessos',
    paint: {
      'circle-color': '#4BF082',
      'circle-radius': 8
    }
  }, {
    id: 'trams',
    type: 'line',
    source: 'trams',
    paint: {
      'line-color': '#4B0082',
      'line-width': 2
    },
    layout: {
      'line-cap': 'round'
    }
  }, {
    id: 'trams-vertex',
    type: 'circle',
    source: 'trams-vertex',
    minzoom: 17,
    paint: {
      'circle-color': '#c79fe4',
      'circle-radius': 4,
      'circle-stroke-color': '#7b06d8',
      'circle-stroke-width': 3,
    }
  },], [features]);

  return <Map
    mapStyle={mapStyle} viewport={viewport} onViewportChange={onViewportChange} sources={sources} layers={layers}>
    <BaseMapPicker
      styles={MAPSTYLES} selectedStyleId={mapStyle} onStyleChange={onMapStyleChange} position="top-right"
      direction="down"
    />
    {drawEnabled && features &&
      <DrawParadaControl
        snappingData={features}
        maxDistanceInMeters={MAX_DISTANCE_IN_METERS}
        onLocation={onParadaPosition}/>
    }
  </Map>;
};

export default MainContent;
