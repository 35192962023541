import React, {FC} from 'react';

import InputField from './InputField';

export type NumericInputProps = {
  label: string,
  value: number,
  required: boolean,
  onChange: (value: number) => void
};

const NumericInput: FC<NumericInputProps> = ({label, value, required, onChange}) =>
  <InputField label={label} value={value} onChange={value => onChange(Number(value))} required={required} type='number'/>;

export default NumericInput;