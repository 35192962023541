import React, {FC} from 'react';

//MUI
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';

//BIKENTA
import DialogPanel from './DialogPanel';

export type LazyLoadingProps = {
  message?: string
};

const LazyLoading: FC<LazyLoadingProps> = ({message = 'Cargando...'}) => {
  return <DialogPanel
    onClose={() => undefined}
    label={message}
    isOpen={true}
  >
    <Box sx={{
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      height: 100,
      width: 300
    }}>
      <CircularProgress sx={{mb: 2}}/>
    </Box>
  </DialogPanel>;
};


export default LazyLoading;