import React, {FC} from 'react';

//MUI
import Box from '@mui/material/Box';
import FormControl from '@mui/material/FormControl';

//UTILS
import {AdapterDayjs} from '@mui/x-date-pickers/AdapterDayjs';
import {LocalizationProvider} from '@mui/x-date-pickers/LocalizationProvider';
import {DatePicker} from '@mui/x-date-pickers/DatePicker';

import {Dayjs} from 'dayjs';

export type DatePickerProps = {
  label: string,
  readonly: boolean,
  required: boolean,
  value?: Dayjs,
  onChange: (value?: Dayjs) => void
};

const CalendarPicker: FC<DatePickerProps> = ({label, required, readonly = false, value, onChange,}) => {
  return <Box>
    <FormControl fullWidth required={required}>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <DatePicker
          value={value}
          disabled={readonly}
          label={label}
          slotProps={{
            textField: {size: 'small'},
            field: {clearable: true}
          }}
          onChange={(day) => onChange(day == null ? undefined : day)}
        />
      </LocalizationProvider>
    </FormControl>
  </Box>;
};

export default CalendarPicker;
