import React, {FC} from 'react';
import SvgIcon from '@mui/material/SvgIcon';
import {SvgIconProps} from '@mui/material/SvgIcon/SvgIcon';

const CreateBusStopIcon: FC<SvgIconProps> = (props) => (
  <SvgIcon
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    width={24}
    height={24}
    viewBox="413.195 413.195 24 24"        
    {...props}>
    <path d="M416.075 416.386h-.566a2.137 2.137 0 0 0-2.133 2.125v.57c0 1.141.911 2.074 2.042 2.124v11.088h-.301c-.646 0-1.172.525-1.172 1.172h3.628c0-.648-.527-1.172-1.176-1.172h-.23v-11.088a2.128 2.128 0 0 0 2.035-2.124v-.57a2.133 2.133 0 0 0-2.127-2.125zM434.683 416.135h-3.832c0-.938-.758-1.696-1.698-1.696h-1.85c-.939 0-1.698.758-1.698 1.696h-3.776c-1.291 0-2.389 1.052-2.389 2.34v13.524c0 .869.668 1.574 1.531 1.574h.419v.808c0 .869.698 1.57 1.574 1.57a1.57 1.57 0 0 0 1.567-1.57v-.808h7.337v.808c0 .869.703 1.57 1.571 1.57a1.57 1.57 0 0 0 1.573-1.57v-.808h.419c.865 0 1.58-.705 1.58-1.574v-13.524a2.335 2.335 0 0 0-2.328-2.34zm-14.082 2.341c0-.673.55-1.223 1.229-1.223h12.851c.674 0 1.224.549 1.224 1.223v.174c0 .661-.562 1.218-1.224 1.218H421.83a1.223 1.223 0 0 1-1.229-1.218v-.174zm1.258 13.25a1.397 1.397 0 1 1 0-2.796c.771 0 1.401.627 1.401 1.398a1.4 1.4 0 0 1-1.401 1.398zm-.029-5.16a1.223 1.223 0 0 1-1.229-1.218v-3.389c0-.669.55-1.22 1.229-1.22h12.851c.674 0 1.224.55 1.224 1.22v3.389a1.24 1.24 0 0 1-1.224 1.218H421.83zm12.765 5.16a1.398 1.398 0 1 1 0-2.796 1.397 1.397 0 1 1 0 2.796z"/>
  </SvgIcon>
);

export default CreateBusStopIcon;
