import React, {ChangeEvent, FC} from 'react';

import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';

export type InputFieldProps = {
  label: string,
  value: number | string,
  required: boolean,
  type: 'number' | 'text',
  multiline?: boolean,
  onChange: (value: number | string) => void
};

const InputField: FC<InputFieldProps> = ({label, value, required, type, multiline=false, onChange}) => {

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => onChange(type === 'number' ? Number(e.target.value) : e.target.value);

  return <>
    <Box mb={1.5}>
      <TextField variant='outlined' inputProps={{ type: type}} fullWidth size='small' margin='dense'
        multiline={multiline} minRows={multiline ? 3 : undefined} label={label} value={value} required={required}
        onChange={handleChange}/>
    </Box>
  </>;
};

export default InputField;