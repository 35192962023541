import React, {useCallback, useEffect, useState} from 'react';
import Layout from '@/components/Layout';
import SidePanelContent from './SidePanelContent';
import MainContent from './MainContent';
import LazyLoading from '@/components/LazyLoading';
import ErrorDialog from '@/components/ErrorDialog';


import {API_DEBOUNCE_MS, INITIAL_MAPSTYLE_URL, INITIAL_VIEWPORT, Mode} from '@/config';
import {useAsync} from 'react-use';
import {Viewport} from '@geomatico/geocomponents/types/common';
import dayjs, {Dayjs} from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';

import pDebounce from 'p-debounce';

import {createParada} from '@/domain/usecases/paradaUseCases';
import {logout} from '@/domain/usecases/login';
import {Parada, ParadaCreation} from '@/domain/entities/Parada';
import getManifestData from '@/domain/usecases/getManifestData';
import getAllFeatures from '@/domain/usecases/getAllFeatures';
import {ParadaLocationEvent} from '@/components/snapping/DrawParadaMode';
import {TramIntersection} from '@/domain/entities/Tram';

dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.tz.setDefault('Europe/Madrid');

type ParadaState = {
  parada: Partial<Parada | ParadaCreation>,
  tramIntersection?: TramIntersection
}

const initialParadaState: ParadaState = {
  parada: {}
};

const Index = () => {
  const [mapStyle, setMapStyle] = useState(INITIAL_MAPSTYLE_URL);
  const [viewport, setViewport] = useState<Viewport>(INITIAL_VIEWPORT);
  const [day, setDay] = useState<Dayjs>(dayjs.tz().startOf('day'));
  const [activeMode, setActiveMode] = useState<Mode>(Mode.VIEW);
  const [isSaving, setSaving] = useState(false);

  // currently editing
  const [paradaState, setParadaState] = useState<ParadaState>(initialParadaState);

  const getFeatures = useCallback(pDebounce(getAllFeatures, API_DEBOUNCE_MS), []);
  const {error, loading, value: features} = useAsync(() => getFeatures(day), [viewport, day, activeMode]);
  // TODO TMB-379 handle errors on manifest request
  const {value: manifestData} = useAsync(getManifestData, []);

  // Clean parada when mode changes
  useEffect(() => {
    setParadaState(initialParadaState);
  }, [activeMode]);

  const handleParadaPosition = (event: ParadaLocationEvent) => {
    setParadaState({
      parada: {
        ...paradaState.parada,
        position: event.location,
      },
      tramIntersection: event.isEndpoint ? undefined : event.tramIntersection,
    });
  };

  const handleParadaProps = (parada: Partial<Parada>) =>
    setParadaState({
      ...paradaState,
      parada: {
        ...paradaState.parada,
        ...parada
      }
    });

  const handleParadaSaved = (parada: (Parada | ParadaCreation)) => {
    setSaving(true);
    createParada(parada, paradaState.tramIntersection)
      .then(() => setActiveMode(Mode.VIEW));
  };

  useEffect(() => {
    if (!loading) setSaving(false);
  }, [loading]);

  const showLoading = isSaving || (loading && !features);

  const sidePanelContent = <SidePanelContent
    mode={activeMode}
    parada={paradaState.parada}
    paradaTypes={manifestData?.paradaTypes}
    onParadaChange={handleParadaProps}
    onParadaSaved={handleParadaSaved}
  />;

  const mainContent = <MainContent
    mode={activeMode}
    features={features}
    mapStyle={mapStyle}
    onMapStyleChange={setMapStyle}
    viewport={viewport}
    onViewportChange={setViewport}
    onParadaPosition={handleParadaPosition}
  />;

  return <>
    {showLoading && <LazyLoading/>}
    {error && <ErrorDialog message={error.message}/>}
    <Layout
      sidePanelContent={sidePanelContent}
      miniSidePanelSelectedActionId={activeMode}
      onActionChanged={(mode) => setActiveMode(mode)}
      mainContent={mainContent}
      day={day}
      onDayChanged={setDay}
      onLogout={logout}
    >
    </Layout>
  </>;
};

export default Index;
