import MapboxDraw from '@mapbox/mapbox-gl-draw';
import {Point} from 'geojson';

export type Position = {
  longitude: number,
  latitude: number
}

export const fromMapboxToGeometry = (point: MapboxDraw.DrawPoint): Point => ({
  type: 'Point',
  coordinates: point.getCoordinate()
});

export const toGeometry = (position: Position): Point => position && ({
  type: 'Point',
  coordinates: [position.longitude, position.latitude]
});