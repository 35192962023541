import {FeatureCollection, LineString, Point} from 'geojson';
import {AllEntities, AllFeatures} from '@/domain/entities/AllEntities';

export const asFeatures = (entities?: AllEntities): AllFeatures | undefined => {
  if (entities === undefined) {
    return undefined;
  }

  const parades: FeatureCollection<Point> = {
    type: 'FeatureCollection',
    features: entities.parades.map(({position, id}) => ({
      type: 'Feature',
      id,
      properties: {},
      geometry: position
    }))
  };
  const accessos: FeatureCollection<Point> = {
    type: 'FeatureCollection',
    features: entities.accessos.map(({position, id}) => ({
      type: 'Feature',
      id,
      properties: {},
      geometry: position
    }))
  };
  const trams: FeatureCollection<LineString> = {
    type: 'FeatureCollection',
    features: entities.trams.map(({geometry, id}) => ({
      type: 'Feature',
      id,
      properties: {},
      geometry
    }))
  };

  return {parades, accessos, trams};
};