import Keycloak from 'keycloak-js';


const keycloak = new Keycloak({
  url: 'https://auth.tmb.cat/auth',
  realm: 'internal',
  clientId: 'gis4-web'
});

const login = async () =>
  keycloak.init({
    onLoad: 'login-required',
    checkLoginIframe: false
  });

const updateToken = () =>
  keycloak.updateToken(30);

const getToken = () => keycloak.token;

const logout = () => keycloak.logout();
const forceNewLogin = () => keycloak.login();


export default {
  login,
  logout,
  updateToken,
  getToken,
  forceNewLogin
};

