import React, {FC} from 'react';

import {Navigate, Outlet} from 'react-router-dom';
import {useAsync} from 'react-use';
import {login} from '@/domain/usecases/login';
import i18n from 'i18next';

const PrivateRoute: FC = () => {
  const state = useAsync(login, []);

  return state.loading ?
    <div>Loading...</div> :
    state.value ? <Outlet/> : <Navigate to={`/${i18n.resolvedLanguage}/404`} />;
};

export default PrivateRoute;