import React, {FC, useCallback, useMemo, useState} from 'react';
import Map from '@geomatico/geocomponents/Map/Map';

import {MAPSTYLES, Mode} from '@/config';
import BaseMapPicker from '@geomatico/geocomponents/Map/BaseMapPicker';
import mapboxgl, {MapLayerMouseEvent} from 'mapbox-gl';
import {Viewport} from '@geomatico/geocomponents/types/common';
import DrawParadaControl from '@/components/DrawParadaControl';
import {ParadaLocationEvent} from '@/components/snapping/DrawParadaMode';
import {AllFeatures} from '@/domain/entities/AllEntities';
import DrawTramControl from '@/components/DrawTramControl';

const MAX_DISTANCE_IN_METERS = 20;
const DRAW_MODES = [Mode.CREATE_BUS_STOP];


export type MainContentProps = {
  mode: Mode,
  features?: AllFeatures,
  viewport: Viewport,
  mapStyle: string,
  onMapStyleChange: (style: string) => void,
  onViewportChange: (viewport: Viewport) => void
  onParadaPosition: (event: ParadaLocationEvent) => void
  onParadaSelected: (selectedParadaId?: number) => void
  selectedParadaId?: number
};

const MainContent: FC<MainContentProps> = ({
  mode,
  features,
  mapStyle,
  viewport,
  onMapStyleChange,
  onViewportChange,
  onParadaPosition,
  onParadaSelected,
  selectedParadaId,
}) => {
  const drawEnabled = DRAW_MODES.includes(mode);
  const [cursor, setCursor] = useState<string>('auto');
  const sources = useMemo((): mapboxgl.Sources => !features ? {} : ({
    'parades': {
      type: 'geojson',
      data: features.parades
    },
    'accessos': {
      type: 'geojson',
      data: features.accessos
    },
    'trams': {
      type: 'geojson',
      data: features.trams
    },
    'trams-vertex': {
      type: 'geojson',
      data: {
        type: 'FeatureCollection',
        features: mode === Mode.CREATE_BUS_STOP ? features.trams.features.map(feature => ({
          ...feature,
          geometry: {
            type: 'MultiPoint',
            coordinates: [
              feature.geometry.coordinates[0],
              feature.geometry.coordinates[feature.geometry.coordinates.length - 1]
            ]
          }
        })) : []
      }
    },
  }), [features, mode]);

  // TODO TMB-403 symbolization
  const layers = useMemo((): Array<mapboxgl.AnyLayer> => !features ? [] : [{
    id: 'accessos',
    type: 'circle',
    source: 'accessos',
    paint: {
      'circle-color': '#4BF082',
      'circle-radius': 8
    }
  }, {
    id: 'trams',
    type: 'line',
    source: 'trams',
    paint: {
      'line-color': '#4B0082',
      'line-width': 2
    },
    layout: {
      'line-cap': 'round'
    }
  }, {
    id: 'trams-vertex',
    type: 'circle',
    source: 'trams-vertex',
    minzoom: 17,
    paint: {
      'circle-color': '#c79fe4',
      'circle-radius': 4,
      'circle-stroke-color': '#7b06d8',
      'circle-stroke-width': 3,
    }
  }, {
    id: 'parades',
    type: 'circle',
    source: 'parades',
    paint: {
      'circle-color': '#FB5082',
      'circle-radius': selectedParadaId !== undefined ? 3 : 6
    }
  }, {
    id: 'parades-selected-outter',
    type: 'circle',
    source: 'parades',
    filter: selectedParadaId !== undefined ? ['==', ['id'], selectedParadaId] : ['==', ['boolean', true], false],
    paint: {
      'circle-color': '#FB5082',
      'circle-radius': 9
    },
    layout: {
      visibility: selectedParadaId !== undefined ? 'visible' : 'none'
    }
  }, {
    id: 'parades-selected-inner',
    type: 'circle',
    source: 'parades',
    filter: selectedParadaId !== undefined ? ['==', ['id'], selectedParadaId] : ['==', ['boolean', true], false],
    paint: {
      'circle-color': '#fbed50',
      'circle-radius': selectedParadaId !== undefined ? 6 : 0
    }
  },], [features, selectedParadaId]);

  const handleClick = (event: MapLayerMouseEvent) => {
    const paradaId = event.features?.[0]?.id;
    onParadaSelected(paradaId !== undefined ? Number(paradaId) : undefined);
  };

  const onMouseEnter = useCallback(() => setCursor('pointer'), []);
  const onMouseLeave = useCallback(() => setCursor('grab'), []);

  return <Map
    mapStyle={mapStyle}
    viewport={viewport}
    onViewportChange={onViewportChange}
    sources={sources}
    layers={layers}
    cursor={cursor}
    onMouseEnter={onMouseEnter}
    onMouseLeave={onMouseLeave}
    interactiveLayerIds={['parades']}
    onClick={handleClick}
  >
    <BaseMapPicker
      styles={MAPSTYLES} selectedStyleId={mapStyle} onStyleChange={onMapStyleChange} position="top-right"
      direction="down"
    />
    {drawEnabled && features &&
      <DrawParadaControl
        snappingData={features}
        maxDistanceInMeters={MAX_DISTANCE_IN_METERS}
        onLocation={onParadaPosition}/>
    }
    { /* TODO remove when integrated with the whole use case */ }
    {features && !drawEnabled &&
      <DrawTramControl
        snappingData={features}
        onGeometryDrawn={console.log}/>
    }
  </Map>;
};

export default MainContent;
