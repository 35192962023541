import {Parada, ParadaCreation} from '@/domain/entities/Parada';
import Http from '@/domain/services/Http';
import {ParadaDto, ParadaDtoCreation} from '@/repository/parada/ParadaDto';
import {toDto} from './opMappers';
import {fromDto} from '../parada/paradaMappers';
import {TramIntersection} from '@/domain/entities/Tram';


export const PATH = 'op';

export interface OpRepository {
  newParada: (newParada: ParadaCreation, tramIntersection: TramIntersection) => Promise<Parada>;
}

export const OpRepository = (apiBaseUrl: URL | string, http: Http): OpRepository => ({
  newParada: (parada, tramIntersection) =>
    http<ParadaDto, ParadaDtoCreation>(new URL(`${PATH}/new-parada`, apiBaseUrl), {
      method: 'POST',
      body: toDto({...parada, ...tramIntersection})
    })
      .then(parada => fromDto(parada)),
});
