import {ParadaTypeDto} from '@/repository/paradaType/paradaTypeDto';
import {ParadaType} from '@/domain/entities/ParadaType';

export const fromDto = (dto: ParadaTypeDto): ParadaType => {
  return {
    ...dto,
    updatedAt: new Date(dto.updatedAt),
    createdAt: new Date(dto.createdAt),
  };
};
