import {FeatureCollection, LineString} from 'geojson';
import {Tram, TramCreation} from '@/domain/entities/Tram';
import {inject} from '@/domain/dependencyInjection';
import {REPOSITORIES, Repository} from '@/domain/services/Repository';


export const getAllTrams = async (date: Date): Promise<Tram[]> => {
  const repository = inject<Repository<Tram, TramCreation>>(REPOSITORIES.tram);

  return repository.getAll(date);
};

export const getAllTramsAsFeatureCollection = async (date: Date): Promise<FeatureCollection<LineString>> => {
  const repository = inject<Repository<Tram, TramCreation>>(REPOSITORIES.tram);

  const trams = await repository.getAll(date);

  return {
    type: 'FeatureCollection',
    features: trams.map(({geometry, id}) => ({
      type: 'Feature',
      id,
      properties: {},
      geometry: geometry
    }))
  };
};
