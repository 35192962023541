import React, {FC} from 'react';

import InputField from './InputField';

export type TextInputProps = {
  label: string,
  value?: string,
  required: boolean,
  onChange: (value: string) => void
};

const TextInput: FC<TextInputProps> = ({label, value, required, onChange}) =>
  <InputField label={label} value={value || ''} onChange={value => onChange(value.toString())} required={required} type='text'/>;

export default TextInput;