export const REPOSITORIES = {
  acces: 'repository.acces',
  parada: 'repository.parada',
  tram: 'repository.tram',
  op: 'repository.op',
  paradaType: 'repository.parada-type'
};

export interface Repository<TEntity, TEntityCreation> {
  list: (date: Date) => Promise<Array<TEntity>>
  create: (entity: TEntityCreation) => Promise<TEntity>
  put: (entityId: number, entity: TEntity) => Promise<TEntity>
}
