import React, {FC} from 'react';
import SectionTitle from './SectionTitle';
import NumericInput from './forms/NumericInput';
import {useTranslation} from 'react-i18next';
import TextInput from './forms/TextInput';
import SelectInput from './forms/SelectInput';
import CalendarPicker from './forms/CalendarPicker';
import dayjs, {Dayjs} from 'dayjs';
import Box from '@mui/material/Box';
import {Button} from '@mui/material';
import {fromPartial, Parada, ParadaCreation} from '@/domain/entities/Parada';
import {ParadaType} from '@/domain/entities/ParadaType';

export type ParadaCardProps = {
  parada: Partial<Parada>,
  availableTypes: ParadaType[],
  onChange?: (parada: Partial<Parada>) => void
  onSave?: (parada: Parada | ParadaCreation) => void
};

const DEFAULT_CITY_CODE = 0;
const DEFAULT_CODE = 0;

const getTypeOptions = (availableTypes: ParadaType[]) => availableTypes.map(availableType => ({
  id: availableType.id + '',
  label: availableType.name
}));

const getSelectedTypeId = (parada: Partial<Parada>, availableTypes: ParadaType[]): string => {
  if ('typeId' in parada) {
    const type = availableTypes.find(type => type.id == parada.typeId);
    return type ? `${type.id}` : '';
  } else {
    return '';
  }
};

const toDayjs = (date?: Date) => date && dayjs(date);

const ParadaDetail: FC<ParadaCardProps> = ({parada, availableTypes, onChange, onSave}) => {
  const {t} = useTranslation();

  const changeName = (value: string) => onChange?.({...parada, name: value});
  const changeType = (value: string) => onChange?.({...parada, typeId: Number(value)});
  const changeCityCode = (value: number) => onChange?.({...parada, cityCode: value});
  const changeCode = (value: number) => onChange?.({...parada, code: value});
  const changeStartDate = (value?: Dayjs) => onChange?.({...parada, startDate: value?.toDate()});
  const changeEndDate = (value?: Dayjs) => onChange?.({...parada, endDate: value?.toDate()});

  const entity = fromPartial(parada);
  return <>
    <SectionTitle titleKey="addStop"/>
    <TextInput label={t('parada_name')} value={parada.name} required={true} onChange={changeName}/>
    <SelectInput label={t('parada_type')} required={true}
      value={getSelectedTypeId(parada, availableTypes)}
      options={getTypeOptions(availableTypes)}
      onChange={changeType}/>
    <NumericInput label={t('parada_city_code')} value={parada.cityCode || DEFAULT_CITY_CODE} required={true}
      onChange={changeCityCode}/>
    <NumericInput label={t('parada_code')} value={parada.code || DEFAULT_CODE} required={true} onChange={changeCode}/>
    <Box mb={2.5}>
      <CalendarPicker label={t('start_date')} readonly={false} required={true} value={toDayjs(parada.startDate)}
        onChange={changeStartDate}/>
    </Box>
    <Box mb={2.5}>
      <CalendarPicker label={t('end_date')} readonly={false} required={false} value={toDayjs(parada.endDate)}
        onChange={changeEndDate}/>
    </Box>
    <Button disabled={!entity} onClick={() => entity && onSave?.(entity)}>{t('save')}</Button>
  </>;
};

export default ParadaDetail;
