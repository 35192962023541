import {Repository} from '@/domain/services/Repository';
import {Parada, ParadaCreation} from '@/domain/entities/Parada';
import Http from '@/domain/services/Http';
import {ParadaDto, ParadaDtoCreation} from './paradaDto';
import {fromDto, toDto} from './paradaMappers';


const PATH= 'parades';

export const ParadaRepository = (apiBaseUrl: URL | string, http: Http): Repository<Parada, ParadaCreation> => ({
  getAll: (date: Date) =>
    http<Array<ParadaDto>>(new URL(`${PATH}?date=${date.toISOString()}`, apiBaseUrl))
      .then(parades => parades.map(parada => fromDto(parada))),
  create: (parada: ParadaCreation) =>
    http<ParadaDto, ParadaDtoCreation>(new URL(PATH, apiBaseUrl), {method: 'POST', body: toDto(parada)})
      .then(dto => fromDto(dto)),
});

export type ParadaRepositoryType = typeof ParadaRepository;

