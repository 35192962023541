import {API_URL} from '@/config';
import {bind} from '@/domain/dependencyInjection';


import jsonFetchHttp from '@/services/Http/jsonFetchHttp';
import keycloakHttp from '@/services/Http/keycloakHttp';
import {AccesRepository} from '@/repository/acces/accesRepository';
import {ParadaRepository} from '@/repository/parada/paradaRepository';
import {TramRepository} from '@/repository/tram/tramRepository';
import {REPOSITORIES} from '@/domain/services/Repository';
import {OpRepository} from '@/repository/op/opRepository';
import {ParadaTypeRepository} from '@/repository/paradaType/paradaTypeRepository';

/***
  Dependency injection composition root, wires implementations for the web application.
 ***/

const httpWithToken = keycloakHttp(jsonFetchHttp);

const baseUrl = API_URL?.endsWith('/') ? API_URL : `${API_URL}/`;

// The REST repository
bind(REPOSITORIES.parada, ParadaRepository(baseUrl, httpWithToken));
bind(REPOSITORIES.tram, TramRepository(baseUrl, httpWithToken));
bind(REPOSITORIES.acces, AccesRepository(baseUrl, httpWithToken));
bind(REPOSITORIES.op, OpRepository(baseUrl, httpWithToken));
bind(REPOSITORIES.paradaType, ParadaTypeRepository(baseUrl, httpWithToken));
