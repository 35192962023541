// eslint-disable-next-line @typescript-eslint/ban-types
const bindings: Record<string, Object> = {};

// eslint-disable-next-line @typescript-eslint/ban-types
export const bind = (key: string, value: Object) => {
  bindings[key] = value;
};

export const inject = <T>(key: string) => {
  return bindings[key] as T;
};
