import MapboxDraw, {
  DrawCustomModeThis,
  DrawFeature,
  DrawLineString,
  DrawPoint,
  MapMouseEvent
} from '@mapbox/mapbox-gl-draw';
import {fromMapboxToGeometry, Position, toGeometry} from './snapping';
import {Feature, FeatureCollection, LineString, Point, Position as GeojsonPosition} from 'geojson';
import distance from '@turf/distance';
import nearestPointOnLine from '@turf/nearest-point-on-line';
import booleanIntersects from '@turf/boolean-intersects';
import bboxPolygon from '@turf/bbox-polygon';
import {TramIntersection} from '@/domain/entities/Tram';


// https://github.com/mapbox/mapbox-gl-draw/blob/main/docs/MODES.md

export type DrawParadaState = {
  // The line for visualizing the parada, from the intersecting point to the actual location
  drawingLine: MapboxDraw.DrawLineString
  // The next point to be drawn, either the snapped/intersecting point or the actual location
  nextPoint: MapboxDraw.DrawPoint
  // The intersection with the tram
  intersection?: TramPoint,
  // The object representing the snapped point on move (and associated info)
  snappedPoint?: SnapData,
  // Max distance from the intersecting point, in meters
  maxDistanceInMeters: number
  // The map, to fire events
  map: mapboxgl.Map,
  // The data to snap to
  snappingData: Array<Feature<LineString>>,
  // keep a reference to remove from map on stop
  onMoveCallback?: () => void
}

export type DrawParadaOptions = {
  // Max distance from the tram, in meters
  maxDistanceInMeters: number
  // The data to snap to
  snappingData: FeatureCollection<LineString>
}

// triggered when both the first intersecting point and the final location are set
export const EVENT_PARADA_LOCATION = 'draw.parada.location';

type TramPoint = {
  isEndpoint: boolean,
  tramId: number
  position: Position
}

type SnapData = TramPoint & {
  distanceToTram: number
}

export type ParadaLocationEvent = {
  // location for the new parada
  location: Point
  isEndpoint: boolean,
  tramIntersection: TramIntersection
}

export default () => {
  const DRAW_LINE_FEATURE_ID = 'drawingLine';
  const DRAW_POINT_FEATURE_ID = 'drawingPoint';

  const DrawParadaMode = {...MapboxDraw.modes.draw_line_string};

  DrawParadaMode.onSetup = function (this: DrawCustomModeThis, options: DrawParadaOptions) {
    const resetFeature = <T extends DrawFeature>(id: string, type: 'Point' | 'LineString'): T => {
      let feature = this.getFeature(id);
      if (!feature) {
        feature = this.newFeature({
          id,
          type: MapboxDraw.constants.geojsonTypes.FEATURE,
          properties: {},
          geometry: {
            type: type,
            coordinates: [],
          }
        } as Feature<Point | LineString>);
        this.addFeature(feature);
      }

      feature.setCoordinates([]);
      return feature as T;
    };

    const initializeDrawingLine = () => {
      const drawingLine = this.getFeature(DRAW_LINE_FEATURE_ID) as DrawLineString;
      if (!drawingLine || !drawingLine.getCoordinates() || !drawingLine.getCoordinates().length) {
        // if there's no line with at least one coordinate, reset
        return {
          drawingLine: resetFeature<DrawLineString>(DRAW_LINE_FEATURE_ID, 'LineString')
        };
      }

      const longitude = drawingLine.getCoordinates()[0][0];
      const latitude = drawingLine.getCoordinates()[0][1];
      const snapData = snap(filteredFeatures, longitude, latitude);
      const intersects = snapData?.distanceToTram && snapData?.distanceToTram < 5e-6;

      return {
        // return existing line if it intersects any trams; otherwise reset to empty
        drawingLine: intersects ? drawingLine : resetFeature<DrawLineString>(DRAW_LINE_FEATURE_ID, 'LineString'),
        // return intersecting point if it intersects any trams and the drawing line is not already done
        intersection: !intersects || drawingLine.properties?.['done'] ? undefined : snapData
      };
    };

    const getFilteredFeatures = () => {
      const bbox = this.map.getBounds().toArray();
      // TODO TMB-379 move bbox max width to snap to options
      if (bbox[1][0] - bbox[0][0] > 0.01) {
        return [];
      }
      const bboxPolygonFeature = bboxPolygon([bbox[0][0], bbox[0][1], bbox[1][0], bbox[1][1]]);
      return options.snappingData.features.filter(feature => booleanIntersects(bboxPolygonFeature, feature));
    };

    const filteredFeatures = getFilteredFeatures();

    // just for visuals
    const snappedPoint = resetFeature<DrawPoint>(DRAW_POINT_FEATURE_ID, 'Point');
    const {drawingLine, intersection} = initializeDrawingLine();
    this.clearSelectedFeatures();

    const state: DrawParadaState = {
      drawingLine,
      nextPoint: snappedPoint,
      maxDistanceInMeters: options.maxDistanceInMeters,
      map: this.map,
      snappingData: filteredFeatures,
      intersection,
    };

    const onMove = () => {
      state.snappingData = getFilteredFeatures();
    };

    // for removing listener later on close
    state.onMoveCallback = onMove;
    this.map.on('moveend', onMove);

    return state;
  };

  DrawParadaMode.onClick = function (state: DrawParadaState) {
    if (state.intersection) {
      // second point, parada is placed with both intersecting point and actual position
      const event: ParadaLocationEvent = {
        location: fromMapboxToGeometry(state.nextPoint),
        tramIntersection: {
          tramId: state.intersection.tramId,
          tramPoint: toGeometry(state.intersection.position)
        },
        isEndpoint: state.intersection.isEndpoint
      };
      state.drawingLine?.setProperty('done', true);
      state.map.fire(EVENT_PARADA_LOCATION, event);
      state.intersection = undefined;
    } else {
      // first (intersecting) point
      state.intersection = state.snappedPoint;
      state.drawingLine.setCoordinates([state.nextPoint.getCoordinate()]);
      state.drawingLine?.setProperty('done', false);
    }
  };

  DrawParadaMode.onMouseMove = function (state: DrawParadaState, event: MapMouseEvent) {
    let point;
    if (state.intersection) {
      point = calcPointWithMaxDistance(state.intersection, state.maxDistanceInMeters, event);
      state.drawingLine.updateCoordinate('1', point.longitude, point.latitude);
    } else {
      const snappedPoint = snap(state.snappingData, event.lngLat.lng, event.lngLat.lat);
      state.snappedPoint = snappedPoint;
      if (!snappedPoint) {
        state.drawingLine.setCoordinates([]);
        state.nextPoint.setCoordinates([]);
        return;
      }
      point = snappedPoint.position;
    }
    state.nextPoint.updateCoordinate(point.longitude, point.latitude);
  };

  DrawParadaMode.onStop = function (this: DrawCustomModeThis, state: DrawParadaState) {
    if (state.onMoveCallback) this.map.off('moveend', state.onMoveCallback);
  };

  DrawParadaMode.toDisplayFeatures = function (_state, geojson, display) {
    display(geojson);
  };

  return DrawParadaMode;
};

const calcPointWithMaxDistance = (intersection: TramPoint, maxDistanceInMeters: number, event: MapMouseEvent): Position => {
  const from = [event.lngLat.lng, event.lngLat.lat];
  const to = [intersection.position.longitude, intersection.position.latitude];

  if (distance(from, to, {units: 'meters'}) < maxDistanceInMeters) {
    return {
      longitude: event.lngLat.lng,
      latitude: event.lngLat.lat,
    };
  } else {
    // with trigonometry! yay!
    const EARTH_RADIUS = 6_371_000;
    const dx = event.lngLat.lng - intersection.position.longitude;
    const dy = event.lngLat.lat - intersection.position.latitude;
    const bearing = Math.atan2(dx, dy);
    const fromLatitudeInRadians = intersection.position.latitude * (Math.PI / 180);
    const fromLongitudeInRadians = intersection.position.longitude * (Math.PI / 180);

    // from https://www.movable-type.co.uk/scripts/latlong.html
    const toLatitudeInRadians = Math.asin(
      Math.sin(fromLatitudeInRadians) * Math.cos(maxDistanceInMeters / EARTH_RADIUS) +
      Math.cos(fromLatitudeInRadians) * Math.sin(maxDistanceInMeters / EARTH_RADIUS) * Math.cos(bearing));
    const toLongitudeInRadians = fromLongitudeInRadians + Math.atan2(
      Math.sin(bearing) * Math.sin(maxDistanceInMeters / EARTH_RADIUS) * Math.cos(fromLatitudeInRadians),
      Math.cos(maxDistanceInMeters / EARTH_RADIUS) - Math.sin(fromLatitudeInRadians) * Math.sin(toLatitudeInRadians));
    return {
      longitude: toLongitudeInRadians * (180 / Math.PI),
      latitude: toLatitudeInRadians * (180 / Math.PI)
    };
  }
};

const snap = (snappingData: Array<Feature<LineString>>, longitude: number, latitude: number): SnapData | undefined => {
  if (snappingData.length === 0) {
    return;
  }

  let closestPoint: GeojsonPosition = [];
  let closestDistance: number = Number.MAX_VALUE;
  let closestFeature: Feature<LineString> = snappingData[0];

  // loop through the layers
  snappingData.forEach(feature => {
    const nearestPoint = nearestPointOnLine(feature, [longitude, latitude]);
    if (nearestPoint.properties.dist < closestDistance) {
      closestPoint = nearestPoint.geometry.coordinates;
      closestDistance = nearestPoint.properties.dist;
      closestFeature = feature;
    }
  });

  const start = closestFeature.geometry.coordinates[0];
  const end = closestFeature.geometry.coordinates[closestFeature.geometry.coordinates.length - 1];
  const distanceStartToClosest = distance(start, closestPoint);
  const distanceEndToClosest = distance(end, closestPoint);

  const closestEndpoint = distanceStartToClosest < distanceEndToClosest ? start : end;
  const shortestDistance = Math.min(distanceStartToClosest, distanceEndToClosest);

  if (!closestFeature.id) {
    throw new Error('Cannot find id for feature');
  }

  // TODO TMB-379 move to options, potentially using meters
  return shortestDistance < 0.005 ? {
    tramId: Number(closestFeature.id),
    isEndpoint: true,
    position: {
      longitude: closestEndpoint[0],
      latitude: closestEndpoint[1]
    },
    distanceToTram: closestDistance
  } : {
    tramId: Number(closestFeature.id),
    isEndpoint: false,
    position: {
      longitude: closestPoint[0],
      latitude: closestPoint[1]
    },
    distanceToTram: closestDistance
  };
};
