import React, {FC} from 'react';

import Box from '@mui/material/Box';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Select, {SelectChangeEvent} from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';

type optionType = {
  id: string,
  label: string
}

export type SelectInputProps = {
  label: string,
  value: string,
  required: boolean,
  options: Array<optionType>
  onChange: (value: string) => void
};

const SelectInput: FC<SelectInputProps> = ({label, value, options, required, onChange}) => {

  const handleChange = (e: SelectChangeEvent) => onChange(e.target.value);

  return <Box mb={1.5}>
    <FormControl fullWidth required={required}>
      <InputLabel>{label}</InputLabel>
      <Select value={value} label={label} onChange={handleChange} size='small'>
        {
          options.map(option => <MenuItem key={option.id} value={option.id}>{option.label}</MenuItem>)
        }
      </Select>
    </FormControl>  
  </Box>;
};

export default SelectInput;