import Http from '@/domain/services/Http';
import {ParadaTypeDto} from '@/repository/paradaType/paradaTypeDto';
import {fromDto} from '@/repository/paradaType/paradaTypeMappers';
import {ParadaType} from '@/domain/entities/ParadaType';


const PATH = 'parada-types';

export interface RepositoryParadaType {
  getAll: () => Promise<ParadaType[]>
}

export const ParadaTypeRepository = (apiBaseUrl: URL | string, http: Http): RepositoryParadaType => ({
  getAll: () => http<Array<ParadaTypeDto>>(new URL(PATH, apiBaseUrl))
    .then(paradaTypes => paradaTypes.map(fromDto))
});
