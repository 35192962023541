import React from 'react';

//ICONS
import CreateBusStopIcon from '../resources/icons/CreateBusStopIcon';

export const DRAWER_WIDTH = 300;
export const SM_BREAKPOINT = 600;
export const MINI_SIDE_PANEL_WIDTH = 61;
export const MINI_SIDE_PANEL_DENSE_WIDTH = 45;

export enum Mode {
  VIEW = 'view',
  CREATE_BUS_STOP = 'create_bus_stop',
  UNIMPLEMENTED = 'unimplemented',
}

export const MINISIDEPANEL_CONFIG = [
  {id: Mode.CREATE_BUS_STOP, route: './map', label: 'Crear Parada', content: <CreateBusStopIcon/>},
  {id: Mode.UNIMPLEMENTED, route: './map', label: '', content: <CreateBusStopIcon/>},
];

export const INITIAL_VIEWPORT = {
  latitude: 41.4,
  longitude: 2.2,
  zoom: 13,
  bearing: -45,
  pitch: 0
};

export const MAPSTYLES = [
  {
    'label': 'Hibrid',
    'thumbnail': 'https://openicgc.github.io/img/orto.png',
    'id': 'https://geoserveis.icgc.cat/contextmaps/hibrid.json',
    'firstTopLayer': 'place-other'
  },
  {
    'label': 'OSM Bright',
    'thumbnail': 'https://openicgc.github.io/img/osm-bright.png',
    'id': 'https://geoserveis.icgc.cat/contextmaps/osm-bright.json',
    'firstTopLayer': 'place-other'
  },
  {
    'label': 'Positron',
    'thumbnail': 'https://openicgc.github.io/img/positron.png',
    'id': 'https://geoserveis.icgc.cat/contextmaps/positron.json',
    'firstTopLayer': 'place_other'
  },
  {
    'label': 'Full Dark',
    'thumbnail': 'https://openicgc.github.io/img/fulldark.png',
    'id': 'https://geoserveis.icgc.cat/contextmaps/fulldark.json',
    'firstTopLayer': 'place-other'
  }
];

export const INITIAL_MAPSTYLE_URL = MAPSTYLES[1].id;

export const API_URL = process.env.API_URL;
export const API_DEBOUNCE_MS = parseInt(process.env.API_DEBOUNCE_MS || '200');
