import {Point} from 'geojson';

export type Parada = {
  id: number
  position: Point
  typeId: number;
  name: string;
  code: number;
  cityCode: number;
  startDate: Date
  endDate?: Date,
}

export type ParadaCreation = Omit<Parada, 'id'>

export const fromPartial = (partialParada: Partial<Parada | ParadaCreation>): Parada | ParadaCreation | undefined => {
  if (
    !partialParada.name || !partialParada.typeId ||
    !partialParada.position || !partialParada.code ||
    !partialParada.cityCode || !partialParada.startDate
  ) {
    return;
  } else {
    return {
      name: partialParada.name,
      typeId: partialParada.typeId,
      position: partialParada.position,
      code: partialParada.code,
      cityCode: partialParada.cityCode,
      startDate: partialParada.startDate,
      ...partialParada
    };
  }
};
