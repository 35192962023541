import {FC, useEffect} from 'react';
import {MapRef, useControl, useMap} from 'react-map-gl';
import MapboxDraw from '@mapbox/mapbox-gl-draw';
import createDrawParadaMode, {
  DrawParadaOptions,
  EVENT_PARADA_LOCATION,
  ParadaLocationEvent,
} from './snapping/DrawParadaMode';
import {BusInventoryFeatures} from '@/domain/usecases/getAllFeatures';

export type DrawParadaControlProps = {
  snappingData: BusInventoryFeatures,
  maxDistanceInMeters: number
  onLocation: (event: ParadaLocationEvent) => void
};

const DrawParadaControl: FC<DrawParadaControlProps> = ({
  snappingData,
  maxDistanceInMeters,
  onLocation
}) => {
  const {current: map} = useMap();
  const addEventHandlers = (map?: MapRef) => {
    map?.on(EVENT_PARADA_LOCATION, onLocation);
  };

  const removeEventHandlers = (map?: MapRef) => {
    map?.off(EVENT_PARADA_LOCATION, onLocation);
  };

  const resetControl = () => {
    const options: DrawParadaOptions = {
      snappingData: snappingData.trams,
      maxDistanceInMeters
    };

    try {
      // changeMode ends up calling <Mode>.onSetup
      // since useControl keeps the control/mode between re-renders, it's the way to pass options and re-initialize
      // the control whenever something else (the data) has changed, without doing weird magic like removing
      // re-adding the control behind the scenes
      control.changeMode('draw_entity', options);
    } catch (e) {
      // changeMode might fail if the control is not yet added to the map; just ignore
    }
  };

  const control = useControl<MapboxDraw>(
    () => new MapboxDraw({
      modes: {...MapboxDraw.modes, draw_entity: createDrawParadaMode()},
      displayControlsDefault: false
    }),
    ({map}) => {
      // on activation
      resetControl();
      addEventHandlers(map);
    },
    ({map}) => removeEventHandlers(map)); // on deactivation

  useEffect(resetControl, [snappingData]);
  useEffect(() => {
    // ensure we replace events on the map when callbacks are changed
    addEventHandlers(map);
    return () => removeEventHandlers(map);
  }, [onLocation]);

  return null;
};

export default DrawParadaControl;
