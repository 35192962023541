import React, {FC} from 'react';

//MUI
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';

//BIKENTA
import DialogPanel from './DialogPanel';
import Typography from '@mui/material/Typography';

export type ErrorDialogProps = {
  title?: string,
  message: string
};

const ErrorDialog: FC<ErrorDialogProps> = ({title = 'Error', message = ''}) => {
  return <DialogPanel
    onClose={() => undefined}
    label={title}
    isOpen={true}
  >
    <Box sx={{
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      height: 100,
      width: 300
    }}>
      <Typography
        variant='body1'
      >
        {message}
      </Typography>
    </Box>
  </DialogPanel>;
};


export default ErrorDialog;