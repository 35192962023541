import React, {FC} from 'react';

import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import styled from '@mui/styles/styled';

import GeomaticoLink from '@/components/GeomaticoLink';
import {Parada, ParadaCreation} from '@/domain/entities/Parada';
import ParadaDetail from '@/components/ParadaDetail';
import {ParadaType} from '@/domain/entities/ParadaType';
import {Mode} from '@/config';

const ScrollableContent = styled(Box)({
  overflow: 'auto',
  padding: '8px',
});

const stackSx = {
  height: '100%',
  overflow: 'hidden'
};

export type SidePanelProps = {
  mode: Mode
  parada: Partial<Parada>
  paradaTypes?: ParadaType[]
  onParadaChange: (parada: Partial<Parada>) => void
  onParadaSaved: (parada: Parada | ParadaCreation) => void
}

const SidePanelContent: FC<SidePanelProps> = ({
  mode,
  parada,
  paradaTypes,
  onParadaChange,
  onParadaSaved
}) =>
  <Stack sx={stackSx}>
    <ScrollableContent>
      {mode === Mode.CREATE_BUS_STOP &&
        paradaTypes &&
        <ParadaDetail parada={parada} availableTypes={paradaTypes} onChange={onParadaChange} onSave={onParadaSaved}/>}
      {/*TODO: eliminar cuando tengamos más modos*/}
      {mode === Mode.UNIMPLEMENTED && <p>Sin implementar</p>}
    </ScrollableContent>
    <GeomaticoLink/>
  </Stack>;

export default SidePanelContent;
