import Http, {HttpError, options} from '@/domain/services/Http';

const jsonFetchHttp: Http = async <RequestType, ResponseType>(url: string | URL, options?: options<RequestType>): Promise<ResponseType> => {

  const response = await fetch(url, {
    method: options?.method ?? 'GET',
    headers: {
      'Content-Type': 'application/json',
      'Accept': 'application/json',
      ...options?.headers
    },
    body: options?.body ? JSON.stringify(options.body) : undefined
  });

  if (response.status === 204 || Number(response.headers.get('content-length')) === 0) {
    return undefined as ResponseType;
  } else if (response.status >= 200 && response.status < 300 && response.ok) {
    return response.json();
  } else {
    // WARNING this can't be expressed in Typescript, but: Statuses other than 2xx are rejected with an HttpError.
    const httpError: HttpError = {
      statusCode: response.status,
      payload: await response.text()
    };
    try {
      httpError.payload = JSON.parse(httpError.payload);
    } catch {
      // Fine, can't parse response as JSON, will assume plain text
    }
    throw httpError;
  }
};

export default jsonFetchHttp;