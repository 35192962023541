import {Repository} from '@/domain/services/Repository';
import {Parada, ParadaCreation} from '@/domain/entities/Parada';
import Http from '@/domain/services/Http';
import {fromDto, toDto, toDtoCreation} from './paradaMappers';
import {ParadaDto, ParadaDtoCreation} from '@/repository/parada/ParadaDto';


export const PATH = 'parades';

export const ParadaRepository = (apiBaseUrl: URL | string, http: Http): Repository<Parada, ParadaCreation> => ({
  list: (date: Date) =>
    http<Array<ParadaDto>>(new URL(`${PATH}?date=${date.toISOString()}`, apiBaseUrl))
      .then(parades => parades.map(parada => fromDto(parada))),
  create: (parada: ParadaCreation) =>
    http<ParadaDto, ParadaDtoCreation>(new URL(PATH, apiBaseUrl), {
      method: 'POST',
      body: toDtoCreation(parada)
    })
      .then(dto => fromDto(dto)),
  put: (paradaId: number, parada: Parada) =>
    http<ParadaDto, ParadaDto>(new URL(`${PATH}/${paradaId}`, apiBaseUrl), {
      method: 'PUT',
      body: toDto(parada)
    })
      .then(dto => fromDto(dto)),
});
