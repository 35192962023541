import React, {FC, SVGProps} from 'react';

const LogoTMBNegativo: FC<SVGProps<SVGSVGElement>> = (props) =>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 396.259 299.648"
    height="100%"
    {...props}
  >
    <path
      fill="#FFF"
      d="M2.47 206.619s-24.735-85.938 82.94-160.058C151.932.764 256.526-13.714 321.613 14.228c59.558 25.621 88.217 74.246 68.421 139.334-19.754 65.086-120.507 146.086-222.695 146.086S32.354 244.691 32.354 195.982c0-92.564 110.884-142.668 110.884-142.668C1.035 96.242 2.47 206.619 2.47 206.619"
    />
    <path
      fill="#E30620"
      d="M98.834 130.305h19.501l-16.209 57.869h24.229l16.208-57.869h19.459l5.318-19.037h-63.146l-5.36 19.037zm55.041 57.869h23.046l13.212-47.19c1.224-4.347 1.478-5.529 2.448-10.341-.422 8.02-.338 8.146-.422 14.352l-.212 43.18h18.023l25.495-45.544c3.081-5.571 3.587-6.669 6.542-12.409-2.279 6.5-2.743 7.936-4.475 13.591l-12.409 44.362h23.089l21.484-76.779H235l-17.896 32.924c-3.799 7.428-4.812 9.371-8.864 18.824.845-6.963 1.014-8.693 1.436-16.291l.464-35.457h-34.695l-21.485 76.779h-.085zm106.663 0h46.979c6.035-.211 14.056-.465 21.442-6.332 5.022-3.926 8.441-9.877 10.003-15.66 1.056-3.799 1.73-8.82-1.435-13.041-2.195-3.039-4.813-3.799-7.725-4.686 2.828-1.182 5.023-2.068 7.683-4.137 3.883-3.039 7.598-7.809 9.201-13.465 1.393-4.896 1.056-10.004-1.52-13.254-4.179-5.656-13-5.994-17.728-6.205h-45.46l-21.484 76.779h.044zm55.125-60.57c2.152.084 7.302.338 5.571 6.669-1.815 6.415-7.471 6.415-9.75 6.415h-13.507l3.672-13.042h14.014v-.042zm-7.472 29.166c2.237.084 8.695.422 6.669 7.598-.548 1.941-1.814 3.799-3.376 4.98-2.406 2.068-4.728 2.152-6.965 2.322h-15.237l4.179-14.9h14.73z"
    />
  </svg>;

export default LogoTMBNegativo;