import {ParadaType} from '@/domain/entities/ParadaType';
import getParadaTypes from '@/domain/usecases/getParadaTypes';

export type ManifestData = {
  paradaTypes: ParadaType[]
}

/**
 * Return all data that can be loaded just once on startup and is not meant to change.
 */
const getManifestData = async (): Promise<ManifestData> => {
  return {
    paradaTypes: await getParadaTypes()
  };
};

export default getManifestData;