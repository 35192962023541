import React, {useCallback, useEffect, useMemo, useState} from 'react';
import Layout from '@/components/Layout';
import SidePanelContent from './SidePanelContent';
import MainContent from './MainContent';
import LazyLoading from '@/components/LazyLoading';
import ErrorDialog from '@/components/ErrorDialog';


import {API_DEBOUNCE_MS, INITIAL_MAPSTYLE_URL, INITIAL_VIEWPORT, Mode} from '@/config';
import {useAsync} from 'react-use';
import {Viewport} from '@geomatico/geocomponents/types/common';
import dayjs, {Dayjs} from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';

import pDebounce from 'p-debounce';

import {createParada, editParada} from '@/domain/usecases/paradaUseCases';
import {logout} from '@/domain/usecases/login';
import {Parada, ParadaCreation} from '@/domain/entities/Parada';
import getManifestData from '@/domain/usecases/getManifestData';
import getAllEntities from '@/domain/usecases/getAllEntities';
import {ParadaLocationEvent} from '@/components/snapping/DrawParadaMode';
import {TramIntersection} from '@/domain/entities/Tram';
import {asFeatures} from '@/services/mapService';

dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.tz.setDefault('Europe/Madrid');

type ParadaState = {
  parada: Partial<Parada | ParadaCreation>,
  tramIntersection?: TramIntersection
}

const initialParadaState: ParadaState = {
  parada: {}
};

const Index = () => {
  const [mapStyle, setMapStyle] = useState(INITIAL_MAPSTYLE_URL);
  const [viewport, setViewport] = useState<Viewport>(INITIAL_VIEWPORT);
  const [day, setDay] = useState<Dayjs>(dayjs.tz().startOf('day'));
  const [activeMode, setActiveMode] = useState<Mode>(Mode.VIEW);
  const [isSaving, setSaving] = useState(false);

  // currently editing
  const [paradaState, setParadaState] = useState<ParadaState>(initialParadaState);
  const selectedParadaId = useMemo(
    () => 'id' in paradaState.parada && paradaState.parada.id !== undefined ? paradaState.parada.id : undefined,
    [paradaState]);

  const getEntities = useCallback(pDebounce(getAllEntities, API_DEBOUNCE_MS), []);
  const {error, loading, value: entities} = useAsync(() => getEntities(day), [viewport, day, activeMode]);
  const features = useMemo(() => asFeatures(entities), [entities]);
  // TODO TMB-379 handle errors on manifest request
  const {value: manifestData} = useAsync(getManifestData, []);

  const resetState = () => setParadaState(initialParadaState);
  useEffect(resetState, [activeMode]);

  const handleParadaPosition = (event: ParadaLocationEvent) => {
    setParadaState({
      parada: {
        ...paradaState.parada,
        position: event.location,
      },
      tramIntersection: event.isEndpoint ? undefined : event.tramIntersection,
    });
  };

  const handleParadaProps = (parada: Partial<Parada>) =>
    setParadaState({
      ...paradaState,
      parada: {
        ...paradaState.parada,
        ...parada
      }
    });

  const handleSave = (promise: Promise<unknown>) => {
    promise
      .then(() => setActiveMode(Mode.VIEW))
      .catch(() => {
        window.alert('Something failed :(');
        setSaving(false);
      });
  };

  const handleParadaSaved = (parada: (Parada | ParadaCreation)) => {
    setSaving(true);
    if (selectedParadaId !== undefined) {
      handleSave(editParada(selectedParadaId, parada as Parada));
    } else {
      handleSave(createParada(parada, paradaState.tramIntersection));
    }
  };

  const handleParadaSelected = (selectedParadaId?: number) => {
    if (selectedParadaId === undefined) {
      if (activeMode === Mode.EDIT_BUS_STOP) {
        setActiveMode(Mode.VIEW);
      }
    } else {
      if (activeMode === Mode.VIEW) {
        setActiveMode(Mode.EDIT_BUS_STOP);
      }

      // changing active mode resets state; setParadaState must be after setActiveMode
      setParadaState({
        ...paradaState,
        parada: entities?.parades.find(parada => parada.id === selectedParadaId) || {}
      });
    }
  };

  useEffect(() => {
    if (!loading) setSaving(false);
  }, [loading]);

  const showLoading = isSaving || (loading && !entities);

  const sidePanelContent = <SidePanelContent
    mode={activeMode}
    parada={paradaState.parada}
    paradaTypes={manifestData?.paradaTypes}
    onParadaChange={handleParadaProps}
    onParadaSaved={handleParadaSaved}
  />;

  const mainContent = <MainContent
    mode={activeMode}
    features={features}
    mapStyle={mapStyle}
    onMapStyleChange={setMapStyle}
    viewport={viewport}
    onViewportChange={setViewport}
    onParadaPosition={handleParadaPosition}
    onParadaSelected={handleParadaSelected}
    selectedParadaId={selectedParadaId}
  />;

  return <>
    {showLoading && <LazyLoading/>}
    {error && <ErrorDialog message={error.message}/>}
    <Layout
      sidePanelContent={sidePanelContent}
      miniSidePanelSelectedActionId={activeMode}
      onActionChanged={(mode) => setActiveMode(mode)}
      mainContent={mainContent}
      day={day}
      onDayChanged={setDay}
      onLogout={logout}
    >
    </Layout>
  </>;
};

export default Index;
